.App {
  text-align: center;
  overflow-x: hidden;
}

/* fonts */
@font-face {
  font-family: "AirbnbCerealBlack";
  src: url(./assets/fonts/AirbnbCerealBlack.ttf);
}
@font-face {
  font-family: "AirbnbCerealBold";
  src: url(./assets/fonts/AirbnbCerealBold.ttf);
}

@font-face {
  font-family: "AirbnbCerealBook";
  src: url(./assets/fonts/AirbnbCerealBook.ttf);
}

@font-face {
  font-family: "AirbnbCerealExtraBold";
  src: url(./assets/fonts/AirbnbCerealExtraBold.ttf);
}

@font-face {
  font-family: "AirbnbCerealLight";
  src: url(./assets/fonts/AirbnbCerealLight.ttf);
}

@font-face {
  font-family: "AirbnbCerealMedium";
  src: url(./assets/fonts/AirbnbCerealMedium.ttf);
}

.carousel-root {
  width: 100%;
}

.carousel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel .slide {
  max-height: 400px;
  background-color: black;
}

.carousel .slide img {
  max-height: 400px;
  object-fit: contain;
}

.carousel-status {
  font-family: "AirbnbCerealMedium";
}

.MuiChip-label {
  font-family: "AirbnbCerealLight";
}

/* react-slick */
.slides {
  position: relative;
}

.slides .slick-prev,
.slides .slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
  /* font-size: 32px; */
}

.slick-prev:before {
  color: #000000 !important;
}
.slick-next:before {
  color: #000000 !important;
}

.slides .slick-prev {
  left: 1%;
}

.slides .slick-next {
  right: 1%;
}
.thumbnail-slider-wrap {
  margin-top: 15px;
  height: 85px;
}
.thumbnail-slider-wrap {
  text-align: center;
}
.slick-track {
  display: flex !important;
  text-align: center;
}
.slick-slide {
  height: inherit !important;
}
.slick-slide > div {
  height: 100%;
}

.slick-initialized {
  padding-bottom: 0px !important;
}
.react-pdf__Page > canvas {
  width: 100% !important;
  height: 100% !important;
}
